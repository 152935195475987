import React from 'react';
import styles from "../loginIcons/LoginIcons.module.css"

const BankIcon = () => {
    return (
        <svg className={styles.self} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.66699 12.0002V7.33355M5.33366 12.0002V7.33355M8.00033 12.0002V7.33355M10.667 12.0002V7.33355M13.3337 12.0002V7.33355M0.666992 14.6669H15.3337M8.24699 1.43355L14.247 3.83355C14.4803 3.92689 14.667 4.20689 14.667 4.45355V6.66689C14.667 7.03355 14.367 7.33355 14.0003 7.33355H2.00033C1.63366 7.33355 1.33366 7.03355 1.33366 6.66689V4.45355C1.33366 4.20689 1.52033 3.92689 1.75366 3.83355L7.75366 1.43355C7.88699 1.38022 8.11366 1.38022 8.24699 1.43355ZM14.667 14.6669H1.33366V12.6669C1.33366 12.3002 1.63366 12.0002 2.00033 12.0002H14.0003C14.367 12.0002 14.667 12.3002 14.667 12.6669V14.6669Z"
                stroke="black" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M8 5.6665C8.13132 5.6665 8.26136 5.64064 8.38268 5.59038C8.50401 5.54013 8.61425 5.46647 8.70711 5.37361C8.79997 5.28075 8.87362 5.17051 8.92388 5.04919C8.97413 4.92786 9 4.79783 9 4.6665C9 4.53518 8.97413 4.40515 8.92388 4.28382C8.87362 4.16249 8.79997 4.05226 8.70711 3.9594C8.61425 3.86654 8.50401 3.79288 8.38268 3.74262C8.26136 3.69237 8.13132 3.6665 8 3.6665C7.73478 3.6665 7.48043 3.77186 7.29289 3.9594C7.10536 4.14693 7 4.40129 7 4.6665C7 4.93172 7.10536 5.18607 7.29289 5.37361C7.48043 5.56115 7.73478 5.6665 8 5.6665V5.6665Z"
                stroke="black" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

    );
};

export default BankIcon;