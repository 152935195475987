import React from 'react';
import styles from "./LoginIcons.module.css"

const Password = () => {
    return (
        <svg className={styles.self} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_587_7331)">
                <path
                    d="M4.59376 11.66L6.12709 13.1934M13.1938 9.95338C12.5427 10.6028 11.7268 11.0621 10.8339 11.2818C9.94102 11.5015 9.00509 11.4732 8.12709 11.2L4.98709 14.3334C4.76042 14.5667 4.31376 14.7067 3.99376 14.66L2.54042 14.46C2.06042 14.3934 1.61376 13.94 1.54042 13.46L1.34042 12.0067C1.29376 11.6867 1.44709 11.24 1.66709 11.0134L4.80042 7.88005C4.26709 6.14671 4.68042 4.18005 6.05376 2.81338C8.02042 0.846712 11.2138 0.846712 13.1871 2.81338C15.1604 4.78005 15.1604 7.98671 13.1938 9.95338V9.95338Z"
                    stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M9.66602 7.33301C9.93123 7.33301 10.1856 7.22765 10.3731 7.04011C10.5607 6.85258 10.666 6.59822 10.666 6.33301C10.666 6.06779 10.5607 5.81344 10.3731 5.6259C10.1856 5.43836 9.93123 5.33301 9.66602 5.33301C9.4008 5.33301 9.14645 5.43836 8.95891 5.6259C8.77137 5.81344 8.66602 6.06779 8.66602 6.33301C8.66602 6.59822 8.77137 6.85258 8.95891 7.04011C9.14645 7.22765 9.4008 7.33301 9.66602 7.33301Z"
                    stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_587_7331">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Password;