import React from 'react';
import styles from './CardItemsIcons.module.css'

const CardIcon2 = () => {
    return (
        <svg className={styles.self} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.3978 35.125V39.3542C22.3978 42.9375 19.0645 45.8333 14.9603 45.8333C10.8561 45.8333 7.50195 42.9375 7.50195 39.3542V35.125C7.50195 38.7083 10.8353 41.25 14.9603 41.25C19.0645 41.25 22.3978 38.6875 22.3978 35.125Z"
                stroke="#0173FF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M22.3952 29.3958C22.3952 30.4375 22.1035 31.3958 21.6035 32.2292C20.3743 34.25 17.8535 35.5208 14.9368 35.5208C12.0202 35.5208 9.49935 34.2292 8.27018 32.2292C7.77018 31.3958 7.47852 30.4375 7.47852 29.3958C7.47852 27.6042 8.31185 26 9.64518 24.8333C10.9993 23.6458 12.8535 22.9375 14.916 22.9375C16.9785 22.9375 18.8327 23.6667 20.1868 24.8333C21.5618 25.9792 22.3952 27.6042 22.3952 29.3958V29.3958Z"
                stroke="#0173FF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M22.3978 29.3957V35.1248C22.3978 38.7082 19.0645 41.2498 14.9603 41.2498C10.8561 41.2498 7.50195 38.6873 7.50195 35.1248V29.3957C7.50195 25.8123 10.8353 22.9165 14.9603 22.9165C17.0228 22.9165 18.877 23.6457 20.2311 24.8123C21.5645 25.979 22.3978 27.604 22.3978 29.3957V29.3957ZM45.8311 22.854V27.1457C45.8311 28.2915 44.9145 29.229 43.7478 29.2707H39.6645C37.4145 29.2707 35.3519 27.6248 35.1644 25.3748C35.0394 24.0623 35.5395 22.8332 36.4145 21.979C37.1853 21.1873 38.2478 20.729 39.4145 20.729H43.7478C44.9145 20.7707 45.8311 21.7082 45.8311 22.854Z"
                stroke="#0173FF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M4.16602 21.8748V17.7082C4.16602 12.0415 7.58268 8.08317 12.8952 7.4165C13.4368 7.33317 13.9993 7.2915 14.5827 7.2915H33.3327C33.8743 7.2915 34.3952 7.31234 34.8952 7.39567C40.2702 8.02067 43.7493 11.9998 43.7493 17.7082V20.729H39.416C38.2493 20.729 37.1868 21.1873 36.416 21.979C35.541 22.8332 35.041 24.0623 35.166 25.3748C35.3535 27.6248 37.416 29.2707 39.666 29.2707H43.7493V32.2915C43.7493 38.5415 39.5827 42.7082 33.3327 42.7082H28.1243"
                stroke="#0173FF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default CardIcon2;