import React from "react";
import styles from "./Dropdown.module.css";

const Dropdown = ({iconBackground}) => {
    return (
        <svg
            className={styles.self}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.2797 5.9668L8.93306 10.3135C8.41973 10.8268 7.57973 10.8268 7.06639 10.3135L2.71973 5.9668"
                stroke={iconBackground ? iconBackground : "#14161A"}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Dropdown;
