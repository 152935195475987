export default {
    mask: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        zIndex: 21,
    },
    maskHidden: {
        display: "none",
    },
    container: {
        position: "fixed",
        top: 0,
        right: "2rem",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        zIndex: 20,
    },
    containerHidden: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: -1,
    },
    panel: {
        backgroundColor: "#fff",
        borderRadius: "3rem",
        boxSizing: "border-box",
        boxShadow: "3.9rem 1.7rem 8.4rem rgba(0, 0, 0, 0.15)",
        transform: "translate3d(0, 0, 0)",
        transition:
            "transform 250ms cubic-bezier(0, 0, 0.25, 1), opacity 250ms cubic-bezier(0, 0, 0.25, 1)",
        zIndex: 22,
    },
    panelHidden: {
        transform: "translate3d(100px, 0, 0)",
        opacity: 0,
        zIndex: -1,
        height: 0,
        width: 0,
        overflow: "hidden",
    },
};
