import React from 'react';
import styles from "./NextIcon.module.css"

const NextIcon = () => {
    return (
        <svg className={styles.self} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.025 11.475L11.975 9.525C12.1125 9.3875 12.1812 9.2125 12.1812 9C12.1812 8.7875 12.1125 8.6125 11.975 8.475L10.0062 6.50625C9.86875 6.36875 9.697 6.303 9.491 6.309C9.2845 6.3155 9.1125 6.3875 8.975 6.525C8.8375 6.6625 8.76875 6.8375 8.76875 7.05C8.76875 7.2625 8.8375 7.4375 8.975 7.575L9.65 8.25H7.23125C7.01875 8.25 6.84375 8.32175 6.70625 8.46525C6.56875 8.60925 6.5 8.7875 6.5 9C6.5 9.2125 6.572 9.3905 6.716 9.534C6.8595 9.678 7.0375 9.75 7.25 9.75H9.65L8.95625 10.4437C8.81875 10.5812 8.753 10.7532 8.759 10.9597C8.7655 11.1657 8.8375 11.3375 8.975 11.475C9.1125 11.6125 9.2875 11.6812 9.5 11.6812C9.7125 11.6812 9.8875 11.6125 10.025 11.475ZM9.5 16.5C8.4625 16.5 7.4875 16.303 6.575 15.909C5.6625 15.5155 4.86875 14.9812 4.19375 14.3062C3.51875 13.6312 2.9845 12.8375 2.591 11.925C2.197 11.0125 2 10.0375 2 9C2 7.9625 2.197 6.9875 2.591 6.075C2.9845 5.1625 3.51875 4.36875 4.19375 3.69375C4.86875 3.01875 5.6625 2.48425 6.575 2.09025C7.4875 1.69675 8.4625 1.5 9.5 1.5C10.5375 1.5 11.5125 1.69675 12.425 2.09025C13.3375 2.48425 14.1312 3.01875 14.8062 3.69375C15.4812 4.36875 16.0155 5.1625 16.409 6.075C16.803 6.9875 17 7.9625 17 9C17 10.0375 16.803 11.0125 16.409 11.925C16.0155 12.8375 15.4812 13.6312 14.8062 14.3062C14.1312 14.9812 13.3375 15.5155 12.425 15.909C11.5125 16.303 10.5375 16.5 9.5 16.5Z"
                fill="#141619"/>
        </svg>
    );
};

export default NextIcon;