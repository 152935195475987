import React from 'react';
import styles from "../nextIcon/NextIcon.module.css"

const PreviousIcon = () => {
    return (
        <svg
            className={styles.self}
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.975 11.475L7.025 9.525C6.8875 9.3875 6.81875 9.2125 6.81875 9C6.81875 8.7875 6.8875 8.6125 7.025 8.475L8.99375 6.50625C9.13125 6.36875 9.303 6.303 9.509 6.309C9.7155 6.3155 9.8875 6.3875 10.025 6.525C10.1625 6.6625 10.2313 6.8375 10.2313 7.05C10.2313 7.2625 10.1625 7.4375 10.025 7.575L9.35 8.25H11.7688C11.9813 8.25 12.1563 8.32175 12.2938 8.46525C12.4313 8.60925 12.5 8.7875 12.5 9C12.5 9.2125 12.428 9.3905 12.284 9.534C12.1405 9.678 11.9625 9.75 11.75 9.75H9.35L10.0438 10.4437C10.1813 10.5812 10.247 10.7532 10.241 10.9597C10.2345 11.1657 10.1625 11.3375 10.025 11.475C9.8875 11.6125 9.7125 11.6812 9.5 11.6812C9.2875 11.6812 9.1125 11.6125 8.975 11.475ZM9.5 16.5C10.5375 16.5 11.5125 16.303 12.425 15.909C13.3375 15.5155 14.1313 14.9812 14.8063 14.3062C15.4813 13.6312 16.0155 12.8375 16.409 11.925C16.803 11.0125 17 10.0375 17 9C17 7.9625 16.803 6.9875 16.409 6.075C16.0155 5.1625 15.4813 4.36875 14.8063 3.69375C14.1313 3.01875 13.3375 2.48425 12.425 2.09025C11.5125 1.69675 10.5375 1.5 9.5 1.5C8.4625 1.5 7.4875 1.69675 6.575 2.09025C5.6625 2.48425 4.86875 3.01875 4.19375 3.69375C3.51875 4.36875 2.9845 5.1625 2.591 6.075C2.197 6.9875 2 7.9625 2 9C2 10.0375 2.197 11.0125 2.591 11.925C2.9845 12.8375 3.51875 13.6312 4.19375 14.3062C4.86875 14.9812 5.6625 15.5155 6.575 15.909C7.4875 16.303 8.4625 16.5 9.5 16.5Z"
                fill="#141619"
            />
        </svg>
    );
};

export default PreviousIcon;