import React from 'react';
import styles from "./LogoutIocns.module.css"

const LogoutIcon = () => {
    return (
        <svg className={styles.self} viewBox="0 0 18 19" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_273_2261)">
                <path
                    d="M17.4373 8.93784H10.3125C10.002 8.93784 9.75 8.68584 9.75 8.37535C9.75 8.06486 10.002 7.81287 10.3125 7.81287H17.4373C17.7478 7.81287 17.9998 8.06486 17.9998 8.37535C17.9998 8.68584 17.7478 8.93784 17.4373 8.93784Z"
                    fill="#C13D3D"/>
                <path
                    d="M14.6248 11.7502C14.4808 11.7502 14.3369 11.6954 14.2273 11.5852C14.0076 11.3654 14.0076 11.0092 14.2273 10.7894L16.6423 8.37458L14.2273 5.95958C14.0076 5.73986 14.0076 5.38364 14.2273 5.16392C14.4471 4.94406 14.8034 4.94406 15.0231 5.16392L17.8355 7.97633C18.0552 8.19605 18.0552 8.55227 17.8355 8.77199L15.0231 11.5844C14.9128 11.6954 14.7689 11.7502 14.6248 11.7502Z"
                    fill="#C13D3D"/>
                <path
                    d="M5.99986 18.4999C5.83932 18.4999 5.68703 18.4774 5.53487 18.4302L1.02142 16.9265C0.407305 16.712 0 16.1397 0 15.5001V2.00049C0 1.17324 0.672754 0.500488 1.5 0.500488C1.66039 0.500488 1.81269 0.52301 1.96498 0.570249L6.4783 2.07396C7.09255 2.28846 7.49972 2.86069 7.49972 3.50035V16.9999C7.49972 17.8272 6.8271 18.4999 5.99986 18.4999ZM1.5 1.62545C1.29374 1.62545 1.12496 1.79423 1.12496 2.00049V15.5001C1.12496 15.6598 1.23221 15.8082 1.38519 15.8615L5.8775 17.3585C5.90977 17.3689 5.95179 17.375 5.99986 17.375C6.20612 17.375 6.37475 17.2062 6.37475 16.9999V3.50035C6.37475 3.34064 6.2675 3.19219 6.11452 3.13891L1.62222 1.64193C1.58995 1.6315 1.54792 1.62545 1.5 1.62545Z"
                    fill="#C13D3D"/>
                <path
                    d="M11.4371 6.50047C11.1266 6.50047 10.8746 6.24848 10.8746 5.93799V2.56309C10.8746 2.04634 10.454 1.62558 9.93721 1.62558H1.49998C1.18949 1.62558 0.9375 1.37358 0.9375 1.06309C0.9375 0.752601 1.18949 0.50061 1.49998 0.50061H9.93721C11.0749 0.50061 11.9996 1.42536 11.9996 2.56309V5.93799C11.9996 6.24848 11.7476 6.50047 11.4371 6.50047Z"
                    fill="#C13D3D"/>
                <path
                    d="M9.93749 16.2501H6.93748C6.62699 16.2501 6.375 15.9981 6.375 15.6876C6.375 15.3771 6.62699 15.1251 6.93748 15.1251H9.93749C10.4542 15.1251 10.8749 14.7044 10.8749 14.1876V10.8127C10.8749 10.5022 11.1269 10.2502 11.4374 10.2502C11.7478 10.2502 11.9998 10.5022 11.9998 10.8127V14.1876C11.9998 15.3253 11.0752 16.2501 9.93749 16.2501Z"
                    fill="#C13D3D"/>
            </g>
            <defs>
                <clipPath id="clip0_273_2261">
                    <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default LogoutIcon;