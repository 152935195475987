import fadeInDown from "./effect/fadeInDown";
import fadeInUp from "./effect/fadeInUp";
import fadeInLeft from "./effect/fadeInLeft";
import fadeInRight from "./effect/fadeInRight";

export default {
    fadeInDown: fadeInDown,
    fadeInUp: fadeInUp,
    fadeInLeft: fadeInLeft,
    fadeInRight: fadeInRight,
};
