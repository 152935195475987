import React from 'react';
import styles from './CardItemsIcons.module.css'

const CardIcon3 = () => {
    return (
        <svg className={styles.self} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M34.1868 8.33321C38.2285 8.33321 41.4785 11.604 41.4785 15.6249C41.4785 19.5624 38.3535 22.7707 34.4577 22.9165C34.2777 22.8957 34.096 22.8957 33.916 22.9165M38.2077 41.6665C39.7077 41.354 41.1243 40.7499 42.291 39.854C45.541 37.4165 45.541 33.3957 42.291 30.9582C41.1452 30.0832 39.7494 29.4999 38.2702 29.1665M19.0827 22.6457C18.8743 22.6249 18.6243 22.6249 18.3952 22.6457C16.0039 22.5645 13.7382 21.5558 12.0777 19.8331C10.4173 18.1105 9.49256 15.8092 9.49935 13.4165C9.49935 8.31238 13.6243 4.16654 18.7493 4.16654C21.1998 4.12234 23.5675 5.0534 25.3315 6.7549C27.0955 8.45641 28.1114 10.789 28.1556 13.2395C28.1998 15.69 27.2687 18.0576 25.5672 19.8216C23.8657 21.5857 21.5332 22.6015 19.0827 22.6457ZM8.66602 30.3332C3.62435 33.7082 3.62435 39.2082 8.66602 42.5624C14.3952 46.3957 23.791 46.3957 29.5202 42.5624C34.5618 39.1874 34.5618 33.6874 29.5202 30.3332C23.8118 26.5207 14.416 26.5207 8.66602 30.3332V30.3332Z"
                stroke="#0173FF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default CardIcon3;