import React from 'react';

const StarIcon = ({...rest}) => {
    return (
        <svg {...rest} width="60" height="57" viewBox="0 0 60 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.2937 4.56229C26.0898 -0.965571 33.9102 -0.965559 35.7063 4.56231L38.5315 13.2574C39.3348 15.7295 41.6385 17.4033 44.2379 17.4033H53.3804C59.1927 17.4033 61.6094 24.8409 56.9071 28.2574L49.5106 33.6312C47.4077 35.1591 46.5278 37.8673 47.331 40.3394L50.1562 49.0345C51.9523 54.5623 45.6254 59.159 40.9232 55.7426L33.5267 50.3688C31.4238 48.8409 28.5762 48.8409 26.4733 50.3688L19.0768 55.7427C14.3745 59.1591 8.04768 54.5623 9.84379 49.0344L12.669 40.3394C13.4722 37.8673 12.5923 35.1591 10.4894 33.6312L3.09289 28.2573C-1.60939 24.8409 0.807274 17.4033 6.61961 17.4033H15.7621C18.3615 17.4033 20.6652 15.7295 21.4685 13.2574L24.2937 4.56229Z"
                fill="silver"
            />
        </svg>
    );
};

export default StarIcon;